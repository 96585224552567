import {getRequest, postRequest, putRequest} from "../../axios";

const baseUrl = `/api/v1/file`;

const savePostFile = (file, postId) =>
    postRequest({
        url: `${baseUrl}/post/${postId}`,
        data: file
    });

const deletePostFile = (postId, fileName) =>
    getRequest({
        url: `${baseUrl}/post/${postId}/${fileName}/delete`,
    })

const getPostPreSignedUrl = (postId, fileName) =>
    getRequest({
        url: `${baseUrl}/post/${postId}/${fileName}`
    })

const uploadWithPreSignedUrl = (presignedUrl, file) =>
    putRequest({
        url: presignedUrl,
        data: file,
    })

export const magazineApi = {
    savePostFile,
    deletePostFile,
    getPostPreSignedUrl,
    uploadWithPreSignedUrl
}
