import React from 'react';
import styled from "@emotion/styled";

const Input = ({readOnly, value, onChangeFormValue}) => {

    return (
        readOnly ? <>{value}</> :
            <InputBox
                type={"text"}
                value={value}
                onChange={(e) => {
                    onChangeFormValue(e.target.value);
                }}
            />
    );
};

export default Input;

const InputBox = styled.input`
  width: 800px;
  height: 40px;
  background: #ffffff;
  font-size: 18px;
  padding-left: 5px;
  font-family: inherit;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  outline: none;

  &::placeholder {
    color: #000000;
  }

  &:focus {
    border: 2px solid gray;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;
