import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import styled from "@emotion/styled";
import Input from "../../component/Input";
import FormItem from "../../component/FormItem";
import Button from "../../component/Button";
import Modal from "react-modal";
import {CloseButton, ModalContent} from "../LoginPage";
import {pistolApi} from "../../api/pistol/pistolApi";
import {useNavigate} from "react-router";
import {Container, ContentsContainer, Title} from "../../pages/layout/LayoutComponents"

const PistolDetailPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [originPistol, setOriginPistol] = useState({showYn: 'N', deleteYn: 'N'});
    const [modifiedPistol, setModifiedPistol] = useState({showYn: 'N', deleteYn: 'N'});

    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Try again.');

    const [selectedRow, setSelectedRow] = useState([]);
    const [fileGridRow, setFileGridRow] = useState([]);

    useEffect(() => {
        (async () => {
            if (id === 'new') return;
            const {data} = await pistolApi.getPistolById(id);
            setModifiedPistol(data);
            setOriginPistol(data);
            setFileGridRow([
                ...data?.fileList?.map((f) => {
                    return {...f, id: f.fileId}
                })
            ])
        })();
    }, [id]);

    const handleFormValueChange = (k, v) => {
        setModifiedPistol({...modifiedPistol, [k]: v});
    }

    const handleClickSave = async () => {
        if (id === 'new') {
            const {data, status} = await pistolApi.createPistol(modifiedPistol);
            if (status === 200) {
                setModifiedPistol(data);
                setOriginPistol(data);
                setModalGuideMsg('Created.');
                setModalOpen(true);
                navigate(`/pistol/${data.id}`)
            } else {
                setModalGuideMsg('Try again.');
                setModalOpen(true);
            }
        } else {
            const {data, status} = await pistolApi.updatePistol(modifiedPistol);
            if (status === 200) {
                setModifiedPistol(data);
                setOriginPistol(data);
                setModalGuideMsg('Updated.');
                setModalOpen(true);
            } else {
                setModalGuideMsg('Try again.');
                setModalOpen(true);
            }
        }
        setTimeout(() => setModalOpen(false), 1000);
    }

    const deletePistol = async () => {
        const {data, status} = await pistolApi.updatePistol({...modifiedPistol, deleteYn: 'Y'});
        if (status === 200) {
            setModifiedPistol(data);
            setOriginPistol(data);
            setModalGuideMsg('Deleted.');
            navigate("/pistol")
            setModalOpen(true);
        } else {
            setModalGuideMsg('Try again.');
            setModalOpen(true);
        }
        setTimeout(() => setModalOpen(false), 1000);
    }

    const handleClickDelete = async () => {
        setDeleteConfirmModalOpen(true);
    }

    const handleClickFileDelete = () => {
        const newFileList = modifiedPistol.fileList.filter(({fileId}) => selectedRow.findIndex((id) => id.toString() === fileId.toString()) < 0);
        setModifiedPistol({...modifiedPistol, fileList: [...newFileList]});
        setFileGridRow([...newFileList]);
    }

    const handleClickFileAdd = () => {
        const randomId = Math.floor(Math.random() * 100000000);
        setFileGridRow([...fileGridRow, {
            id: randomId,
            filePath: '',
            rowNum: '1',
            fileId: randomId,
            fileType: 'image'
        }]);
    }

    if (!modifiedPistol) <div>loading...</div>
    else return (
        <Container>
            <Title>Pistol Detail</Title>
            <Row>
                <Button text="Save" type="primary" onClickButton={handleClickSave}/>
            </Row>
            <ContentsContainer>
                <FormItem label={<Label>ID</Label>} item={<Input value={originPistol?.id} readOnly/>}/>
                <FormItem label={<Label>KEY</Label>} item={<Input value={modifiedPistol?.pistolKey}
                                                                  onChangeFormValue={(value) => handleFormValueChange("pistolKey", value)}/>}/>
                <FormItem label={<Label>AUTHOR</Label>} item={<Input value={modifiedPistol?.author}
                                                                     onChangeFormValue={(value) => handleFormValueChange("author", value)}/>}/>
                <FormItem label={<Label>TITLE</Label>} item={<Input value={modifiedPistol?.title}
                                                                    onChangeFormValue={(value) => handleFormValueChange("title", value)}/>}/>
                <FormItem label={<Label>SUBTITLE</Label>} item={<Input value={modifiedPistol?.subTitle}
                                                                       onChangeFormValue={(value) => handleFormValueChange("subTitle", value)}/>}/>
                {/*<FormItem label={<Label>CONTENT</Label>} item={<Input value={modifiedPistol?.content}*/}
                {/*                                                      onChangeFormValue={(value) => handleFormValueChange("content", value)}/>}/>*/}
                <FormItem label={<Label>CREATE DATE</Label>}
                          item={<Input value={originPistol?.createDate} readOnly/>}/>
                <FormItem label={<Label>UPDATE DATE</Label>}
                          item={<Input value={originPistol?.updateDate} readOnly/>}/>
                {/*<FormItem label={<Label>FILE LIST</Label>} item={*/}
                {/*    <div style={{display: 'flex', flexDirection: 'column', height: 'fit-content'}}>*/}
                {/*        <div style={{*/}
                {/*            display: 'flex',*/}
                {/*            flexDirection: 'row',*/}
                {/*            height: 'fit-content',*/}
                {/*            gap: '15px',*/}
                {/*            paddingBottom: '20px',*/}
                {/*            justifyContent: 'flex-end'*/}
                {/*        }}>*/}
                {/*            <Button text="Delete File" type="secondary"*/}
                {/*                    style={{width: '100px', height: '30px', fontSize: '15px'}}*/}
                {/*                    onClickButton={handleClickFileDelete}/>*/}
                {/*            <Button text="Add File" type="primary"*/}
                {/*                    style={{width: '100px', height: '30px', fontSize: '15px'}}*/}
                {/*                    onClickButton={handleClickFileAdd}/>*/}
                {/*        </div>*/}
                {/*        <div style={{*/}
                {/*            display: 'flex',*/}
                {/*            flexDirection: 'row',*/}
                {/*            height: 'fit-content',*/}
                {/*            gap: '15px',*/}
                {/*            paddingBottom: '20px',*/}
                {/*            justifyContent: 'flex-start',*/}
                {/*            fontWeight: '700'*/}
                {/*        }}>*/}
                {/*            File Type can only be video or image. Row Number can only be 1~5. Type correctly.*/}
                {/*        </div>*/}
                {/*        <DataGrid*/}
                {/*            rows={fileGridRow}*/}
                {/*            getRowId={(row) => row.fileId}*/}
                {/*            columns={[*/}
                {/*                {field: "fileId", headerName: "ID", width: 80},*/}
                {/*                {field: "rowNum", headerName: "ROW NUM", width: 80, editable: true},*/}
                {/*                {field: "fileType", headerName: "FILE TYPE", width: 120, editable: true},*/}
                {/*                {field: "filePath", headerName: "FILE PATH", width: 600, editable: true},*/}
                {/*            ]}*/}
                {/*            style={{maxWidth: "800px"}}*/}
                {/*            checkboxSelection*/}
                {/*            onRowSelectionModelChange={(ids) => {*/}
                {/*                setSelectedRow(ids);*/}
                {/*            }}*/}
                {/*            onProcessRowUpdateError={()=>{}}*/}
                {/*            processRowUpdate={(updatedRow, originalRow) => {*/}
                {/*                let updatedRowList = fileGridRow.map((row) => {*/}
                {/*                    if (row.id === originalRow.id) {*/}
                {/*                        return updatedRow;*/}
                {/*                    } else {*/}
                {/*                        return row;*/}
                {/*                    }*/}
                {/*                });*/}
                {/*                setModifiedPistol({...modifiedPistol, fileList: [...updatedRowList]});*/}
                {/*                setFileGridRow([...updatedRowList]);*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*}/>*/}
                <FormItem label={<Label>SHOW YN</Label>} item={<Input value={modifiedPistol?.showYn || ''}
                                                                      onChangeFormValue={(value) => handleFormValueChange("showYn", value)}/>}/>
            </ContentsContainer>
            <Row>
                <Button text="Delete" type="secondary" onClickButton={handleClickDelete}/>
            </Row>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row', alignItems: 'center'}}>
                    {modalGuideMsg}
                    <CloseButton width="20px" height="20px" onClick={() => setModalOpen(false)}>
                        X
                    </CloseButton>
                </ModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "400px",
                    height: "200px",
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={deleteConfirmModalOpen} onRequestClose={() => setDeleteConfirmModalOpen(false)}>
                <ModalContent style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>
                    Do you surely want to delete?
                    <Row style={{justifyContent: "center", gap: '20px'}}>
                        <Button text="Confirm" type="secondary" style={{width: "100px", height: "35px", fontSize: "15px"}}
                                onClickButton={async () => {
                                    setDeleteConfirmModalOpen(false)
                                    await deletePistol();
                                }}/>
                        <Button text="Cancel" type="primary" style={{width: "100px", height: "35px", fontSize: "15px"}}
                                onClickButton={() => setDeleteConfirmModalOpen(false)}/>
                    </Row>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default PistolDetailPage;

export const Label = styled.div`
  width: 200px;
  height: 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1680px;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 35px;
  box-sizing: border-box;
`;
