import {getRequest, postRequest} from "../../axios";

const baseUrl = `/api/v1/pistol`;

const getPistols = () =>
    getRequest({
        url: `${baseUrl}/all`,
    });

const getPistolById = (pistolId) =>
    getRequest({
        url: `${baseUrl}/${pistolId}`,
    });

const createPistol = (pistol) =>
    postRequest({
        url: `${baseUrl}`,
        data: pistol
    })
const updatePistol = (pistol) =>
    postRequest({
        url: `${baseUrl}/${pistol?.id}`,
        data: pistol
    });

export const pistolApi = {
    getPistols,
    getPistolById,
    createPistol,
    updatePistol
}



