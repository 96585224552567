import {getRequest} from "../../axios";

const baseUrl = `/api/v1/news-letter`;

const getSubscribers = () =>
    getRequest({
        url: `${baseUrl}/subscribers`,
    });

export const newsLetterApi = {
    getSubscribers,
}



