import React, {Suspense, useRef} from 'react';
import styled from 'styled-components';
import Gnb from "./layout/Gnb";
import {Outlet} from "react-router-dom";
import useRouterScrollTop from "../hooks/useRouterScrollTop";

const Contents = (props) => {
    const {children} = props;
    const scrollRef = useRef(null);

    useRouterScrollTop(scrollRef.current);

    return (
        <ScrollBoundary id="offshoot-admin" ref={scrollRef}>
            <Suspense>{children}</Suspense>
        </ScrollBoundary>
    );
};

const ScrollBoundary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 50px);
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
  //@media only screen and (min-width: 1440px) {
  //  overflow-x: hidden;
  //}
`
const HomePage = () => {
    return (
        <Container>
            <Body>
                <Gnb/>
                <Spacing top="50px"/>
                <Contents>
                    <Outlet/>
                </Contents>
            </Body>
        </Container>
    );
};

export default HomePage;

const Container = styled.div`
  max-height: 100vh;
  display: flex;
  max-width: 100vw;
  align-items: flex-start;
  overflow: hidden;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  font-family: 'Helvetica LT W05 Bold';

  & > #contents-utility {
    align-self: stretch;
    marin-top: 50px;
  }
`

const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;

