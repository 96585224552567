import {getRequest, postRequest} from "../../axios";

const baseUrl = `/api/v1/user`;

const login = (user) =>
    postRequest({
        url: `${baseUrl}/login`,
        data: user
    });

const logout = (user) =>
    getRequest({
        url: `${baseUrl}/logout`,
    });

const getUserInfo = () =>
    getRequest({
        url: `${baseUrl}`,
    });

export const userApi = {
    getUserInfo,
    login,
    logout
}


