import {atom, selector} from "recoil";
import { recoilPersist } from 'recoil-persist';
import {userApi} from "../api/user/UserApi";

const { persistAtom } = recoilPersist({
    key: 'persists-atom',
    storage: sessionStorage,
});
export const menuState = atom({
    key: 'menuState',
    default: null,
    effects_UNSTABLE: [persistAtom],
})

const userSelector = selector({
    key: 'userSelector',
    get: async ({get}) => {
        return await userApi.getUserInfo()
    }
})
export const userState = atom({
    key: 'userState',
    default: userSelector,
    effects_UNSTABLE: [persistAtom],
})
