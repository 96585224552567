import styled from "styled-components";

export const Container = styled.div`
  min-width: 100vw;
  min-height: calc(100vh - 50px);;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 30px 35px;
  font-size: 24px;
  font-weight: 700;
  box-sizing: border-box;
`;

export const ContentsContainer = styled.div`
  display: flex;
  position: relative;
  padding: 20px 35px;
  width: 100%;
  max-width: 1680px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
`;
