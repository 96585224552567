import React, { useCallback } from 'react';
import { Outlet, ScrollRestoration } from "react-router-dom";

const RootRoute = () => {
    const getKey = useCallback((location) => location.pathname, []);
    return (
        <>
            <Outlet/>
            {/*<DialogProvider />*/}
            <ScrollRestoration getKey={getKey}/>
        </>
    );
};

export default RootRoute;
