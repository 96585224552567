import React, {useEffect, useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import styled from "styled-components";
import {newsLetterApi} from "../api/newsLetter/NewsLetterApi";

const SubscriberPage = () => {
    const [subscriberList, setSubscriberList] = useState([]);
    useEffect(() => {
        (async () => {
            const {data} = await newsLetterApi.getSubscribers();

            if (data.length <= 0) {
                return;
            }

            let uniqueSubscriber = [...new Set(data?.map((item) => {
                return {id: item.id, domain: item.domain}
            })?.map((o) => JSON.stringify(o)))]?.map((s) => JSON.parse(s));
            uniqueSubscriber = uniqueSubscriber?.map((s, idx) => {
                return {...s, no: idx + 1}
            });
            setSubscriberList(uniqueSubscriber);
        })();
    }, []);
    if (!subscriberList) <div>Loading...</div>
    else return (
        <Container>
            <Title>Subscriber List</Title>
            <ContentsContainer>
                {
                    subscriberList?.length > 0 &&
                    <DataGrid
                        rows={[
                            ...subscriberList
                        ]}
                        columns={[
                            {field: "no", headerName: "NO", width: 100},
                            {field: "id", headerName: "ID", width: 140},
                            {field: "domain", headerName: "DOMAIN", width: 160},
                            {
                                field: "email",
                                headerName: "EMAIL",
                                width: 250,
                                valueGetter: params =>
                                    `${params.row.id || ""}@${params.row.domain || ""}`,
                            },
                        ]}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                    />
                }
            </ContentsContainer>
        </Container>
    );
};

export default SubscriberPage;

const Container = styled.div`
  min-width: 100vw;
  min-height: calc(100vh - 50px);;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 30px 35px;
  font-size: 24px;
  font-weight: 700;
  box-sizing: border-box;
`;

const ContentsContainer = styled.div`
  display: flex;
  position: relative;
  padding: 20px 35px;
  width: 100%;
  max-width: 1680px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
`;
