import {getRequest, postRequest} from "../../axios";

const baseUrl = `/api/v1/magazine`;

const getPosts = () =>
    getRequest({
        url: `${baseUrl}/all`,
    });

const getPostById = (postId) =>
    getRequest({
        url: `${baseUrl}/${postId}`,
    });

const createPost = (post) =>
    postRequest({
        url: `${baseUrl}`,
        data: post
    });

const updatePost = (post) =>
    postRequest({
        url: `${baseUrl}/${post?.id}`,
        data: post
    });



export const magazineApi = {
    getPosts,
    getPostById,
    createPost,
    updatePost
}



