import axios from "axios";

const axiosClient = axios.create({
    baseURL: "https://admin.offshootdot.com",
    // baseURL: "http://localhost:8080",
    withCredentials: true,
});

axiosClient.interceptors.request.use(
    undefined,
    async (error) => {
        switch (error?.response?.status) {
            case 401:
                window.location.replace("/login");
                break;
        }
    }
)

axiosClient.interceptors.response.use(
    undefined,
    async (error) => {
        switch (error?.response?.status) {
            case 401:
                window.location.replace("/login");
                break;
        }
    }
)
export const postRequest = (options) => {
    return axiosClient({
        ...options,
        method: 'POST'
    })
}

export const putRequest = (options) => {
    return axiosClient({
        ...options,
        method: 'PUT'
    })
}

export const getRequest = (options) => {
    return axiosClient({
        ...options,
        method: 'GET'
    })
}
