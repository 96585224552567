import React, {lazy, useEffect} from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import RootRoute from "./RootRoute";
import ClientServerErrorPage from "../pages/error/ClientServerErrorPage";
import HomePage from "../pages/HomePage";
import SubscriberPage from "../pages/SubscriberPage";
import MagazinePage from "../pages/magazine/MagazinePage";
import MagazineDetailPage from "../pages/magazine/MagazineDetailPage";
import PistolPage from "../pages/pistol/PistolPage";
import PistolDetailPage from "../pages/pistol/PistolDetailPage";
import LoginPage from "../pages/LoginPage";

// const HomePage = lazy(() => import('../pages/HomePage'));
// const LoginPage = lazy(async () => await import('../pages/LoginPage'));
// const SubscriberPage = lazy(async () => await import('../pages/SubscriberPage'));
// const MagazinePage = lazy(async () => await import('../pages/magazine/MagazinePage'));
// const MagazineDetailPage = lazy(() => import('../pages/magazine/MagazineDetailPage'));
// const PistolPage = lazy(() => import('../pages/pistol/PistolPage'));
// const PistolDetailPage = lazy(() => import('../pages/pistol/PistolDetailPage'));
//
const pcRoutes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="" element={<RootRoute/>}>
            <Route path="/" element={<HomePage/>} errorElement={<ClientServerErrorPage/>}>
                <Route path="login" element={<LoginPage/>}/>
                <Route path="subscriber" element={<SubscriberPage/>}/>
                <Route path="magazine" element={<MagazinePage/>}/>
                <Route path="magazine/:id" element={<MagazineDetailPage/>}/>
                <Route path="pistol" element={<PistolPage/>}/>
                <Route path="pistol/:id" element={<PistolDetailPage/>}/>
            </Route>
        </Route>
    )
);

export default pcRoutes;

