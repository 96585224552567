import React, {useEffect, useState, useRef, useCallback} from 'react';
import styled from 'styled-components';
import {userState} from "../recoil/atom";
import {useRecoilState} from "recoil";
import {userApi} from "../api/user/UserApi";
import Modal from 'react-modal';

const LoginPage = () => {
    const [userInfo, setUserInfo] = useRecoilState(userState);

    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Check your ID/PW again.');

    const handleClickLoginButton = async () => {
        const {data} = await userApi.login({loginId, password});
        if (data?.loginId === undefined || data?.loginId === null) {
            setModalGuideMsg('Check your ID/PW again.');
            setModalOpen(true);

        } else {
            setModalGuideMsg('Login.');
            setModalOpen(true);
            setUserInfo(data);
            window.location.replace("/");
        }
        setLoginId("");
        setPassword("");
        setTimeout(() => setModalOpen(false), 1000);
    };

    return (
        <Container>
            <Title>Login</Title>
            <ColumnContainer>
                <RowContainer>
                    <Label>
                        ID
                    </Label>
                    <LoginInput value={loginId} onChange={(e) => setLoginId(e.target.value)}/>
                    <Spacing top="30px"/>
                    <Label>
                        PW
                    </Label>
                    <LoginInput value={password} onChange={(e) => setPassword(e.target.value)}/>
                </RowContainer>
                <LoginButton onClick={handleClickLoginButton}>Login</LoginButton>
            </ColumnContainer>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row', alignItems:'center'}}>
                    {modalGuideMsg}
                    <CloseButton width="20px" height="20px" onClick={() => setModalOpen(false)}>
                        X
                    </CloseButton>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default LoginPage;

const Container = styled.div`
  max-height: 100vh;
  display: flex;
  max-width: 100vw;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
  padding: 30px 35px 0 35px;
`

const Title = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  font-size: 24px;
  font-weight: 700;
  box-sizing: border-box;
`;

const ColumnContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

const RowContainer = styled.div`
  display: flex;
  position: relative;
  //padding: 20px 0 20px 0;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const LoginInput = styled.input`
  width: 400px;
  height: 53px;
  background: #ffffff;
  font-size: 30px;
  font-family: inherit;
  box-sizing: border-box;
  border: 1px solid #000;
  position: relative;
  text-align: center;
  outline: none;
  box-sizing: border-box;

  &::placeholder {
    color: #000000;
  }

  &:focus {
    border: 2px solid red;

  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const LoginButton = styled.div`
  background: #000;
  width: 460px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
`;

const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;
