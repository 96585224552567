import React, {useEffect, useState} from 'react';
import {magazineApi} from "../../api/magazine/magazineApi";
import {useParams} from "react-router-dom";
import styled from "@emotion/styled";
import Input from "../../component/Input";
import FormItem from "../../component/FormItem";
import Button from "../../component/Button";
import Modal from "react-modal";
import {CloseButton, ModalContent} from "../LoginPage";
import FileUploader from "../../component/FileUploader";
import {magazineApi as fileApi} from "../../api/common/fileApi";
import {useNavigate} from "react-router";
import Editor from "../../component/Editor";
import {Container, ContentsContainer, Title} from "../../pages/layout/LayoutComponents"
const MagazineDetailPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [originPost, setOriginPost] = useState({});
    const [modifiedPost, setModifiedPost] = useState({showYn: "N", deleteYn: 'N'});

    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Try again.');
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            if (id === 'new') return;
            const {data} = await magazineApi.getPostById(id);
            setModifiedPost(data);
            setOriginPost(data);
        })();
    }, [id]);

    const handleFormValueChange = (k, v) => {
        if (k==='content') {
            console.log(v, "from MagazineDetailPage");
        }
        setModifiedPost({...modifiedPost, [k]: v});
    }

    const handleClickSave = async () => {
        if (id === 'new') {
            const newPost = await magazineApi.createPost({...modifiedPost, categoryThumbnail: "", basicThumbnail: ""});
            if (modifiedPost.basicThumbnail instanceof Array) {
                const preSignedUrl = await fileApi.getPostPreSignedUrl(newPost.data.id, modifiedPost.basicThumbnail[0].name)
                await fileApi.uploadWithPreSignedUrl(preSignedUrl.data, modifiedPost.basicThumbnail[0]);
                modifiedPost["basicThumbnail"] = preSignedUrl.data.split("?")[0];
            }

            if (modifiedPost.categoryThumbnail instanceof Array) {
                const preSignedUrl = await fileApi.getPostPreSignedUrl(newPost.data.id, modifiedPost.categoryThumbnail[0].name)
                await fileApi.uploadWithPreSignedUrl(preSignedUrl.data, modifiedPost.categoryThumbnail[0]);
                modifiedPost["categoryThumbnail"] = preSignedUrl.data.split("?")[0];
            }
            const {data, status} = await magazineApi.updatePost({...modifiedPost, id: newPost.data.id});
            if (status === 200) {
                setModifiedPost(data);
                setOriginPost(data);
                setModalGuideMsg('Created.');
                setModalOpen(true);
                navigate(`/magazine/${newPost.data.id}`);
            } else {
                setModalGuideMsg('Try again.');
                setModalOpen(true);
            }
        } else {
            if (modifiedPost.basicThumbnail instanceof Array) {
                if (originPost.basicThumbnail !== "") {
                    await fileApi.deletePostFile(id, originPost.basicThumbnail.split("/")[5])
                }
                const preSignedUrl = await fileApi.getPostPreSignedUrl(id, modifiedPost.basicThumbnail[0].name)
                await fileApi.uploadWithPreSignedUrl(preSignedUrl.data, modifiedPost.basicThumbnail[0]);
                modifiedPost["basicThumbnail"] = preSignedUrl.data.split("?")[0];
            }

            if (modifiedPost.categoryThumbnail instanceof Array) {
                if (originPost.categoryThumbnail !== "") {
                    await fileApi.deletePostFile(id, originPost.categoryThumbnail.split("/")[5])
                }
                const preSignedUrl = await fileApi.getPostPreSignedUrl(id, modifiedPost.categoryThumbnail[0].name)
                await fileApi.uploadWithPreSignedUrl(preSignedUrl.data, modifiedPost.categoryThumbnail[0]);
                modifiedPost["categoryThumbnail"] = preSignedUrl.data.split("?")[0];
            }
            const {data, status} = await magazineApi.updatePost({...modifiedPost, id: id});
            if (status === 200) {
                setModifiedPost(data);
                setOriginPost(data);
                setModalGuideMsg('Updated.');
                setModalOpen(true);
            } else {
                setModalGuideMsg('Try again.');
                setModalOpen(true);
            }
        }
        setTimeout(() => setModalOpen(false), 1000);
    }

    const deletePost = async () => {
        const {data, status} = await magazineApi.updatePost({...modifiedPost, deleteYn: 'Y'});
        if (status === 200) {
            setModifiedPost(data);
            setOriginPost(data);
            setModalGuideMsg('Deleted.');
            setModalOpen(true);
            navigate("/magazine")
        } else {
            setModalGuideMsg('Try again.');
            setModalOpen(true);
        }
        setTimeout(() => setModalOpen(false), 1000);
    }

    const handleClickDelete = async () => {
        setDeleteConfirmModalOpen(true);
    }

    if (!modifiedPost) <div>loading...</div>
    return (
        <Container>
            <Title>Post Detail</Title>
            <Row>
                <Button text="Save" type="primary" onClickButton={handleClickSave}/>
            </Row>
            <ContentsContainer>
                <FormItem label={<Label>ID</Label>} item={<Input value={originPost?.id} readOnly/>}/>
                <FormItem label={<Label>CATEGORY</Label>} item={<Input value={modifiedPost?.category}
                                                                       onChangeFormValue={(value) => handleFormValueChange("category", value)}/>}/>
                <FormItem label={<Label>AUTHOR</Label>} item={<Input value={modifiedPost?.author}
                                                                     onChangeFormValue={(value) => handleFormValueChange("author", value)}/>}/>
                <FormItem label={<Label>TITLE</Label>} item={<Input value={modifiedPost?.title}
                                                                    onChangeFormValue={(value) => handleFormValueChange("title", value)}/>}/>
                <FormItem label={<Label>SUBTITLE</Label>} item={<Input value={modifiedPost?.subTitle}
                                                                       onChangeFormValue={(value) => handleFormValueChange("subTitle", value)}/>}/>
                <FormItem label={<Label>CONTENT</Label>} item={<Editor value={modifiedPost?.content} onChangeFormValue={(value) => handleFormValueChange("content", value)}/>}/>
                <FormItem label={<Label>COLOR</Label>} item={<Input value={modifiedPost?.color}
                                                                    onChangeFormValue={(value) => handleFormValueChange("color", value)}/>}/>
                <FormItem label={<Label>BASIC THUMBNAIL</Label>}
                          item={<FileUploader initialFile={originPost?.basicThumbnail}
                                              uploadFile={(value) => handleFormValueChange("basicThumbnail", value)}/>}/>
                <FormItem label={<Label>CATEGORY THUMBNAIL</Label>}
                          item={<FileUploader initialFile={originPost?.categoryThumbnail}
                                              uploadFile={(value) => handleFormValueChange("categoryThumbnail", value)}/>}/>
                <FormItem label={<Label>CREATE DATE</Label>}
                          item={<Input value={originPost?.createDate} readOnly/>}/>
                <FormItem label={<Label>UPDATE DATE</Label>}
                          item={<Input value={originPost?.updateDate} readOnly/>}/>
                <FormItem label={<Label>RELATED POST ID</Label>}
                          item={<Input value={modifiedPost?.relatedPostIdList?.join(",")}
                                       onChangeFormValue={(value) => handleFormValueChange("relatedPostIdList", value?.split(","))}/>}/>
                <FormItem label={<Label>SHOW YN</Label>} item={<Input value={modifiedPost?.showYn}
                                                                      onChangeFormValue={(value) => handleFormValueChange("showYn", value)}/>}/>
            </ContentsContainer>
            <Row>
                <Button text="Delete" type="secondary" onClickButton={handleClickDelete}/>
            </Row>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row', alignItems: 'center'}}>
                    {modalGuideMsg}
                    <CloseButton width="20px" height="20px" onClick={() => setModalOpen(false)}>
                        X
                    </CloseButton>
                </ModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "400px",
                    height: "200px",
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={deleteConfirmModalOpen} onRequestClose={() => setDeleteConfirmModalOpen(false)}>
                <ModalContent style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '30px'}}>
                    Do you surely want to delete?
                    <Row style={{justifyContent: "center", gap: '20px'}}>
                        <Button text="Confirm" type="secondary" style={{width: "100px", height: "35px", fontSize: "15px"}}
                                onClickButton={async () => {
                                    setDeleteConfirmModalOpen(false)
                                    await deletePost();
                                }}/>
                        <Button text="Cancel" type="primary" style={{width: "100px", height: "35px", fontSize: "15px"}}
                                onClickButton={() => setDeleteConfirmModalOpen(false)}/>
                    </Row>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MagazineDetailPage;

export const Label = styled.div`
  width: 200px;
  height: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1680px;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 35px;
  box-sizing: border-box;
`;

