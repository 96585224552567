import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import styled from "styled-components";
import AceEditor from "react-ace";

const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'align',
    'color',
    'background',
    'size',
    'h1',
    'link',
    'image'
];

const Editor = ({value, onChangeFormValue}) => {
    const pretty = require('pretty')
    const handleValueChange = (value) => {
        onChangeFormValue && onChangeFormValue(value);
    }
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    // [{size: ['small', false, 'large', 'huge']}],
                    [{size: [false, false, false, false]}],
                    [{align: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{list: 'ordered'}, {list: 'bullet'}],
                    [{color: []}, {background: []}],
                    // ['link', 'image'],
                ],
            },
        };
    }, []);
    //
    // useEffect(() => {
    //     const prettifier = setInterval(() => onChangeFormValue(pretty(value)), 3000);
    //     return () => clearInterval(prettifier);
    // }, [])
    return (
        <EditorWrapper>
            <AceEditor
                // fontSize={fontSize}
                style={{ display: "flex", width: "1200px", height: "400px", boxSizing: "border-box" }}
                mode="html"
                // theme={theme}
                onChange={handleValueChange}
                placeholder={"Start coding"}
                value={value}
                editorProps={{$blockScrolling: true}}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                }}
            ></AceEditor>
            <ContentContainer>
                <Content dangerouslySetInnerHTML={{__html: value}}/>
            </ContentContainer>
            {/*<ReactQuill*/}
            {/*    style={{width: "400px", height: "500px", paddingBottom: "40px"}}*/}
            {/*    theme="snow"*/}
            {/*    modules={modules}*/}
            {/*    formats={formats}*/}
            {/*    value={value}*/}
            {/*    // onChange={handleValueChange}*/}
            {/*></ReactQuill>*/}
        </EditorWrapper>

    )
}

export default Editor;

const EditorWrapper = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`
const ContentContainer = styled.div`
  width: 1200px;
  height: 400px;
  max-height: 400px;
  overflow: scroll;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  color: #000;
  font-family: "Helvetica LT W05 Roman";
  font-size: 18px;
  line-height: 150%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    position: relative;
    width: 100%;
    height: auto;
    min-width: 900px;
    max-width: 1100px;
    object-fit: contain;
  }

  p {
    width: 50vw;
  }

  figure {
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover figcaption {
      visibility: visible;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;
      min-width: 900px;
      max-width: 1100px;
      object-fit: contain;
    }

    figcaption {
      width: 100%;
      height: 30px;
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      color: white;
      display: flex;
      align-items: flex-end;
      padding: 0 0 0 4px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      bottom: 0;
      visibility: hidden;
      z-index: 2;
    }
  }
`;
