import React, {useEffect, useMemo} from "react";
import {RecoilRoot, useRecoilState} from 'recoil';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FloatingTree} from '@floating-ui/react';
import {RouterProvider, useLocation} from 'react-router-dom';
import pcRoutes from "./routes/pcRoutes";

import './App.css';

function App() {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    return (
        <React.StrictMode>
            <RecoilRoot>
                <FloatingTree>
                    <DndProvider backend={HTML5Backend}>
                        <RouterProvider router={pcRoutes}/>
                    </DndProvider>
                </FloatingTree>
            </RecoilRoot>
        </React.StrictMode>
    );
}

export default App;
