import React, {useRef, useState} from 'react';
import LogoImgSrc from "../asset/img__logo-close.png";
import styled from "styled-components";

const FileUploader = ({initialFile, multiple, uploadFile}) => {
    const [, setFiles] = useState([]);
    const [imgFile, setImgFile] = useState();
    const imgRef = useRef();

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
        uploadFile && uploadFile(Array.from(e.target.files));

        if (!multiple) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImgFile(reader?.result);
            };
        }
    }

    return (
        <form>
            {multiple ? <input type="file" multiple onChange={handleFileChange}/>
                : <SingleImageUploader>
                    <input type="file" onChange={handleFileChange}/>
                    <img style={{width: "100px", height: "100px"}} ref={imgRef} src={imgFile || initialFile || LogoImgSrc} alt="업로드한 이미지"/>
                </SingleImageUploader>}

        </form>
    );
};

export default FileUploader;

const SingleImageUploader = styled.div`
  width: 100%;
  height: 130px;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
