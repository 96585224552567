import React, {useContext, useState} from 'react';
import styled from "styled-components";

import LogoImgSrc from "../../asset/img__logo-close.png";
import {useRecoilState} from "recoil";
import {menuState, userState} from "../../recoil/atom";
import {useNavigate} from "react-router";
import {userApi} from "../../api/user/UserApi";
import Modal from "react-modal";

const Gnb = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useRecoilState(userState);
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Logout.');

    const handleClickLogoutButton = async () => {
        await userApi.logout();
        setModalOpen(true);
        window.location.replace("/");
        setUserInfo(null);
        setTimeout(() => setModalOpen(false), 1000);
    };

    return (
        <Container>
            <MenuContainer>
                <LogoWrapper
                    onClick={() => {
                        navigate("/");
                        setSelectedMenu("");
                    }
                    }>
                    <img src={LogoImgSrc}/>
                </LogoWrapper>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('news-letter')}
                          onClick={() => {
                              navigate("/subscriber");
                          }}>
                        Subscriber
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('magazine')}
                          onClick={() => {
                              navigate("/magazine");
                          }}>
                        Magazine
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('pistol')}
                          onClick={() => {
                              navigate("/pistol");
                          }}>
                        Pistol
                    </Menu>
                    <Menu size="20px" onClick={handleClickLogoutButton}>
                        Logout
                    </Menu>
                </MenuWrapper>
            </MenuContainer>
            <Modal style={{
                content: {
                    width: "400px",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row', alignItems: 'center'}}>
                    {modalGuideMsg}
                    <CloseButton width="20px" height="20px" onClick={() => setModalOpen(false)}>
                        X
                    </CloseButton>
                </ModalContent>
            </Modal>
        </Container>
    )
};

export default Gnb;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //max-width: calc(max(100vw, 1630px));
  width: 100%;
  min-width: calc(max(750px, 100vw));
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;

  padding: 0 20px;

  z-index: 5;
  position: fixed;
`;

const MenuWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //gap: 10px;
  text-align: center;
`;

const Menu = styled.div`
  width: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({size}) => size && size};
  font-weight: 700;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:#000000'};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;

const CloseButton = styled.div`
  font-size: 30px;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
`;
