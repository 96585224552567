import React, {useEffect, useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useNavigate} from "react-router";
import {pistolApi} from "../../api/pistol/pistolApi";
import Button from "../../component/Button";
import {Row} from "./PistolDetailPage";
import {Container, ContentsContainer, Title} from "../../pages/layout/LayoutComponents"
const PistolPage = () => {

    const navigate = useNavigate();

    const [pistolList, setPistolList] = useState([]);
    useEffect(() => {
        (async () => {
            const {data} = await pistolApi.getPistols();
            setPistolList(data);
        })();
    }, []);

    const handleRowClick = (params) => {
        navigate(`/pistol/${params.row.id}`);
    }

    const handleClickCreate = () => {
        navigate(`/pistol/new`)
    }

    return (
        <Container>
            <Title>Pistol List</Title>
            <Row>
                <Button text="Create" type="Secondary" onClickButton={handleClickCreate}/>
            </Row>
            <ContentsContainer>
                {
                    pistolList?.length > 0 &&
                    <DataGrid
                        rows={[
                            ...pistolList
                        ]}
                        columns={[
                            {field: "id", headerName: "ID", width: 100},
                            {field: "pistolKey", headerName: "KEY", width: 140},
                            {field: "author", headerName: "AUTHOR", width: 160},
                            {field: "title", headerName: "TITLE", width: 300},
                            {field: "subTitle", headerName: "SUBTITLE", width: 220},
                            {field: "deleteYn", headerName: "DELETE_YN", width: 160},
                            {field: "showYn", headerName: "SHOW_YN", width: 160},
                        ]}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                        onRowClick={handleRowClick}
                    />
                }
            </ContentsContainer>
        </Container>
    );
};

export default PistolPage;
