import React from 'react';
import styled from "@emotion/styled";

const FormItem = ({label, item}) => {
    return (
        <FormContainer>
            {label}
            {item}
        </FormContainer>
    );
};

export default FormItem;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 0;
`;
