import React, {useEffect, useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {magazineApi} from "../../api/magazine/magazineApi";
import {useNavigate} from "react-router";
import Button from "../../component/Button";
import {Row} from "../pistol/PistolDetailPage";
import {Container, ContentsContainer, Title} from "../../pages/layout/LayoutComponents"
const MagazinePage = () => {
    const navigate = useNavigate();

    const [postList, setPostList] = useState([]);
    useEffect(() => {
        (async () => {
            const {data} = await magazineApi.getPosts();
            setPostList(data);
        })();
    }, []);

    const handleRowClick = (params) => {
        navigate(`/magazine/${params.row.id}`);
    }

    const handleClickCreate = () => {
        navigate(`/magazine/new`)
    }

    return (
        <Container>
            <Title>Posts List</Title>
            <Row>
                <Button text="Create" type="Secondary" onClickButton={handleClickCreate}/>
            </Row>
            <ContentsContainer>
                {
                    postList?.length > 0 &&
                    <DataGrid
                        rows={[
                            ...postList
                        ]}
                        columns={[
                            {field: "id", headerName: "ID", width: 100},
                            {field: "category", headerName: "CATEGORY", width: 140},
                            {field: "author", headerName: "AUTHOR", width: 160},
                            {field: "title", headerName: "TITLE", width: 300},
                            {field: "subTitle", headerName: "SUBTITLE", width: 220},
                            {field: "color", headerName: "COLOR", width: 160},
                            {field: "showYn", headerName: "SHOW_YN", width: 160},
                        ]}
                        getRowId={() => Math.floor(Math.random() * 100000000)}
                        onRowClick={handleRowClick}
                    />
                }
            </ContentsContainer>
        </Container>
    );
};

export default MagazinePage;
