import React from 'react';
import styled from "@emotion/styled";

const Button = ({style, type, text, onClickButton}) => {
    return (
        <UnstyledButton style={{...style}} type={type} onClick={() => {onClickButton && onClickButton()}}>
            {text}
        </UnstyledButton>
    );
};

export default Button;

const UnstyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  width: 145px;
  height: 40px;
  font-size: 21px;
  color: ${({type}) => type === 'primary' ? '#ffffff' : 'gray'};
  background-color: ${({type}) => type === 'primary' ? 'gray' : '#ffffff'};
  border: ${({type}) => type === 'primary' ? 'none' : '1px solid gray'};
  
  &:hover {
    cursor: pointer;
  }
`;
